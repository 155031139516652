import { Skeleton } from "@mui/material";

export function ContextSkeleton()
{
  return (
    <>
    <Skeleton width="100%" />
    <Skeleton width="100%" />
    <Skeleton width="80%" />
    </> 
  )
}