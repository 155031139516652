/* 房源搜索 以地址为关键字 */
import { useRef, useState } from "react"

import { Chip, IconButton, Stack, List, Paper, InputBase, LinearProgress, ListItem, ListItemText, ListItemButton, Typography } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material"
import Dialog from "./Dialog";
import { addressSearch } from "api/property";
import { enqueueSnackbar } from "notistack";
import { openPropertyDialog } from "utils/property";

export default function Search()
{

  const ref = useRef()
  const [ properties, setProperties ] = useState([])
  const [ keywords, setKeywords ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const openDialog = () => {
    setProperties([])
    ref.current.open()
  }

  const changeKeywords = (e) => {
    setKeywords(e.target.value)
  }

  const search = async () => {
    console.log(keywords)
    if(keywords && keywords !== '')
    {
      if(keywords.length < 2) enqueueSnackbar("关键字长度太短", { variant: "warning"})
      setLoading(true)
      const data = await addressSearch(keywords)
      setProperties(data)
      setLoading(false)
    } else {
      enqueueSnackbar("请输入关键字", { variant: "warning"})
    }
  }

  return (
  <>
    <Chip icon={<SearchIcon fontSize="small" />} label="查询" onClick={openDialog}/>
  
    <Dialog title="房源搜索" ref={ref} width="xs" fullWidth>
      <Stack spacing={2}>

        <SearchBar 
          placeholder="房源编号或地址关键字" 
          value={keywords} 
          onChange={changeKeywords} 
          onSubmit={search} 
        />
        
        {loading && <LinearProgress />}

        <List>
        {properties.map(property => 
          <ListItemButton key={property.id} onClick={()=>openPropertyDialog(property.id)} sx={{paddingX:0}}>
            <ListItem sx={{background: 'white'}}>
              <ListItemText
                primary={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Chip label={"编号 "+property.id} size="small" />
                  {property.availability && <Chip label={"空房 "+property.availability} size="small" />}
                  <Typography>£{property.rent}/周</Typography>
                </Stack>
                }
                secondary={
                  <Stack spacing={1}>
                    <Stack direction='row' spacing={1} alignItems='center' mt={1}>
                      
                      <Typography>{property.name}</Typography>
                    </Stack>
                    <Typography variant="caption">{property.address}</Typography>
                  </Stack>
                }
              />
            </ListItem>  
          </ListItemButton>
        )}
        </List>
      </Stack>
    </Dialog>
  </>
  )
}

function SearchBar ({placeholder, value, onChange, onSubmit}) {


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit()
    }
  }

  return (
    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton type="button" sx={{ p: '10px' }} onClick={onSubmit}>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}