/* 顾问留言备注 */
import { useState, useEffect } from 'react'
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { call } from 'api/api';
import { Close } from '@mui/icons-material';

// API
/* 备注 */
export const getComments = async (propertyID) => {
  return await call('admin/comment/get', {tid: propertyID})
}

// 添加备注
// data {message, propertyID}
export const addComment = async (data) => {
  return await call('admin/comment/add', data)
}

// 删除备注
export const delComment = async (commentID) => {
  return await call('admin/comment/del', {id: commentID})
}

export default function AdminComments({propertyID}) {

  const [message, setMessage] = useState('')
  const [comments, setComments] = useState([])
  const [inputDisabled, setInputDisabled] = useState(false)

  const fetchData = async () => {
    const data = await getComments(propertyID)
    if(data) {
      setComments(data)
    }
  }

  useEffect(() => {
    fetchData() 
  }, [])

  const changeMessage = (e) => {
    setMessage(e.target.value)
  }

  const sendMessage = async (e) => {
    if(e.key === 'Enter') {
      setInputDisabled(true)
      await addComment({tid: propertyID, message: message})
      await fetchData()
      setInputDisabled(false)
    }
  }

  const delMessage = async (commentID) => {
    if(window.confirm('确定删除?')){
      await delComment(commentID)
      await fetchData()
    }
  }

  return (
    <Stack spacing={1}>
      <TextField 
        disabled={inputDisabled}
        size='small' 
        value={message}
        onKeyDown={sendMessage} 
        onChange={changeMessage}
      />
      {comments && comments.map(comment => 
      <Box key={comment.id}>
        <Typography variant='body2'>{comment.name}: {comment.message}</Typography>
        <Typography variant='caption'>{comment.date}</Typography>
        <IconButton size="small" onClick={()=>delMessage(comment.id)}>
          <Close fontSize='1'/>
        </IconButton>
      </Box>
      )}
    </Stack>
  )
}

