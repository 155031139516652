import { Skeleton } from "@mui/material";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper'
import AspectRatio from '@mui/joy/AspectRatio';

// 图片 幻灯片
export default function PropertyImgSlider({property}) {

  return(
    <>
    {property && 
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        loop={true}
      >
        {property.imgs.map(img => 
          <SwiperSlide key={img.url}>
            <AspectRatio ratio="3/2">
              <img src={img.url} alt="" width="100%" loading="lazy" />
            </AspectRatio>
          </SwiperSlide>
        )}
      </Swiper>
    }
    {!property && <Skeleton variant="rectangular" width="100%" height={40} /> }
    </>
  )
}