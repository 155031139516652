import { useState } from 'react'
import { Chip, Menu, MenuItem } from '@mui/material'

export default function Selector({label, items, callback}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const selectItem = (item) => {
    if(callback) callback(item)
    closeMenu()
  }

  return (
    <>

    <Chip label={label} onClick={openMenu} />

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
    >
      {items.map((item,index) => <MenuItem key={index} onClick={()=>selectItem(item)} dense>{item}</MenuItem>)}
    </Menu>
    
    </>
  )
}