import { authSite } from "utils/common";
import { call, redirect } from "./api";

/* 登录状态 */
export const authCheck = async () => {
  return await call('auth/check/london')
}

/* 登录入口 */
export const authSignIn = () => {
  redirect(authSite() + '?from='+window.location.href)
}

/* 登出网站 */
export const authSignOut = () => {
  redirect(authSite() + 'signout')
}