
import { useRef } from "react";
import { Button, Typography, Stack } from "@mui/material";
import Dialog from "components/Dialog";

// 租房流程 简介
export default function ProviderNote({provider})
{

  const ref = useRef()
  const open = () => {
    ref.current.open()
  }

  return (
  <>
  <Button variant="outlined" onClick={open}>流程</Button>
  <Dialog
    ref={ref} 
    title={provider.name} 
  >
    <Stack spacing={2}>
      <Typography>官网</Typography>
      <Typography variant="body2">{provider.website}</Typography>

      <Typography>联系人</Typography>
      <Typography variant="body2" whiteSpace='pre-wrap'>{provider.contact}</Typography>

      <Typography>流程</Typography>
      <Typography variant="body2" whiteSpace='pre-wrap'>{provider.process}</Typography>
    </Stack>
  </Dialog>
  </>
  )

}