import { useRef } from "react";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { Button, Stack, Typography } from "@mui/material";

import Dialog from "components/Dialog";

/* 客服按钮 */
export default function CustomerService () {
  const ref = useRef()
  const { isMobile } = useSelector(appStates)
  const url = "https://work.weixin.qq.com/kfid/kfc6221ea9c975fe8a6"

  const handleClick = () => {
    if(isMobile) {
      window.open(url)
    }else{
      ref.current.open()
    }
  }

  return (
    <>
    <Button color="success" variant="contained" onClick={handleClick}>顾问微信</Button>
    <Dialog ref={ref} title="微信客服" width="xs">
      <Stack alignItems="center" spacing={2}>
        <img src="https://london.51room.com/qrcode.png" alt="qrcode" />
        <Typography variant="body2">微信扫一扫</Typography>
        <Typography variant="caption">如果您没有微信请发邮件联系 booking@51room.com</Typography>
      </Stack>
    </Dialog>
    </>
  )
}