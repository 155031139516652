import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { Button } from "@mui/material";
import Dialog from "components/Dialog";

/* 地图 */
export default function Map ({lat, lng}) {
  const [center, setCenter] = useState(null)

  const ref = useRef()
  const open = () => {
    ref.current.open()
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB1bDvfxb2oH49S817960_gdLQi22oUUYY"
  })

  useEffect(()=>{
    setCenter({
      lat: Number(lat),
      lng: Number(lng)
    })// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isMobile } = useSelector(appStates)

  return (
    <>
    { isLoaded && center &&
    <>
    <Button variant="outlined" onClick={open}>地图</Button>
    <Dialog
      ref={ref} 
      title="地图" 
      width="lg"
      fullScreen={isMobile}
      fullWidth
      noContentPadding={true}
    >
      <GoogleMap
        mapContainerStyle={{width:'100%',height:'92vh'}}
        center={center}
        zoom={14}
      >
        <Marker position={center} />
      </GoogleMap>
    </Dialog>
    </>
    }
    </>
  )

}