

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// React Router
import router from 'router';
import { useEffect } from 'react';
import { RouterProvider } from "react-router-dom";

// Mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux
import { useDispatch } from 'react-redux';
import { setIsMobile, setIsStaff, setSigned } from 'redux/appSlice';


// API
import { authCheck } from 'api/auth';
import { versionCheck } from "utils/common";


function App() {

  const dispatch = useDispatch()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // 初始化
  const init = async () => {
    console.log('init')

    // 是否是手机浏览
    dispatch(setIsMobile(isMobile))

    // 是否已经登录
    const res = await authCheck()
    // console.log(res)
    dispatch(setSigned(res.signed))
    if(res.staff) dispatch(setIsStaff(res.staff))
    if(res.version) versionCheck(res.version)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
