import axios from 'axios'
import qs from 'qs'
import { authSite, noticeError } from 'utils/common';

axios.defaults.withCredentials = true;

let endpoint = 'https://api2.51room.com/';
if(process.env.NODE_ENV === 'development') {
  endpoint = "http://localhost:8080/";
}

export const call = async (uri, data) => {
  try {

    let headers = {"Content-Type": "application/json"}
    let response = null
    
    if(data) {
      console.log('post ' + endpoint + uri)
      response = await axios.post(endpoint + uri, qs.stringify(data), headers);
    } else {
      response = await axios.get(endpoint + uri, headers);
    }
    
    console.log(response)

    if(response) {
      const data = response.data;
      if(data.error) 
      {
        noticeError(data.message)
        return null
      }
      return data;
    }

    else return response;
  } catch (error) {
    console.log('call error')
    console.error(error);
    if(error.response && error.response.status === 401) redirect(authSite())
    return null;
  }
}

export const upload = async (uri, data) => {
  try {

    let headers = {"Content-Type": "multipart/form-data"}
    let response = null
    
    response = await axios.post(endpoint + uri, data, headers);

    console.log(response)

    if(response) {
      const data = response.data;
      if(data.error) 
      {
        noticeError(data.message)
        return null
      }
      return data;
    }

    else return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function redirect(url) 
{
  window.location.href = url
}