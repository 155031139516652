const propertyForm = [
  {
    contents: [
      {
        type: 'checkbox',
        name: 'mark_hide',
        label: '隐藏'
      },
    ]
  },
  {
    contents: [
      {
        type: 'input',
        name: 'name',
        label: '标题',
        required: true
      },
      {
        type: 'date',
        name: 'availability',
        label: '空房',
        required: true
      }
    ]
  },
  {
    contents: [
      {
        type: 'input',
        name: 'rent',
        label: '每周租金',
        required: true
      },
      {
        type: 'input',
        name: 'lat',
        label: '纬度 lat'
      },
      {
        type: 'input',
        name: 'lng',
        label: '经度 lng'
      }
    ]
  },
  {
    type: 'input',
    name: 'address',
    label: '地址',
    required: true
  },
  {
    type: 'note',
    name: 'intro',
    label: '介绍'
  }
]

export default propertyForm