import { propertySearch } from "api/property"
import { useEffect, useState } from "react"
import { Unstable_Grid2 as Grid, Box, Card, CardContent, Chip, Typography, Stack, Pagination, LinearProgress, Hidden } from "@mui/material"
import { AspectRatio } from "@mui/joy"
import { Filter, FilterChips } from "components/PropertyFilter";
import Search from "components/Search";
import { openPropertyDialog } from "utils/property"
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";

import { baseUrl } from "utils/common";

export default function Home() {

  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const [filter, setFilter] = useState({})

  async function fetchData() {
    console.log(filter)
    if(Object.keys(filter).length === 0) return false
    const data = await propertySearch(filter)
    console.log(data)
    setLoading(false)
    if(!data) return false;
    setProperties(data.properties)
    setPages(data.pages)
  }

  useEffect(() => {
    setFilter({
      ...filter,
      page: page
    })
  }, [page])

  useEffect(() => {
    setLoading(true)
    setPages(0)
    setProperties([])
    fetchData()
  }, [filter])

  const clearFilter = () => {
    setFilter({page: 1})
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems='center' spacing={2}>
        <Typography variant="h5">伦敦公寓出租</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* Desktop */}
        <Hidden mdDown><FilterChips filter={filter} clear={clearFilter} /></Hidden>
        <Filter filter={filter} setFilter={setFilter} />
        <Search />
      </Stack>

      {/* Mobile */}
      <Hidden mdUp><FilterChips filter={filter} clear={clearFilter} /></Hidden>
      
      {loading && <LinearProgress />}
      <Properties data={properties} page={page} pages={pages} onChangePage={setPage} />
    </Stack>
  )
}

/**
 * 房源列表
 */
const Properties = (props) => {

  const { data, page, pages, onChangePage } = props
  const { mobile } = useSelector(appStates)

  const changePage = (event, number) => {
    onChangePage(number)
  }

  const viewProperty = (property) => {
    if(mobile) openPropertyDialog(property.id)
    else window.open(baseUrl() + 'property/'+property.id)
  }

  return(
    <Stack spacing={3}>
      {data && 
      <Grid container spacing={2}>
        {data.map(property => 
          <Grid key={property.id} xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card>
              <Box onClick={()=>viewProperty(property)}>
                <AspectRatio ratio="3/2">
                  <img src={property.img} width="100%" alt={property.name} />
                </AspectRatio> 
              </Box>
              <CardContent>
                <Stack alignItems='center'>
                  <Box onClick={()=>viewProperty(property.id)}>
                    <Typography variant="h6">{property.name}</Typography>
                  </Box>
                  <Typography variant="caption">{property.address}</Typography>
                  <Stack direction="row" spacing={1} mt={1}>
                    <Chip label={"£"+property.rent+' / 周'} size="small" color="error" />
                    {property.availability && <Chip label={property.availability+" 空房"} size="small" />}
                  </Stack>
                  
                </Stack>
              </CardContent>
              
            </Card>
          </Grid>
        )}
      </Grid> 
      }
      <Stack alignItems='center'>
      {pages > 1 && <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={changePage} />}
      </Stack>

    </Stack>
  )
}