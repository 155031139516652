import { Alert, Box, Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ErrorPage()
{

  const navigate = useNavigate()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box alignSelf='center' mt={1} mx={1}>
                <img alt="logo" src="https://img.51room.com/web/logo-399x143.png" width="250" />
              </Box>
              <Alert severity="error">此页面不存在或已过期</Alert>
              <Button variant="outlined" onClick={()=>navigate('/')} >返回首页</Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>         
    </Grid> 
  )
}