import { call } from "./api";

/* 房源渠道 列表 */
export const providerList = async () => {
  return await call('london/provider-list')
}

/* 房源渠道 更新 */
export const providerUpdate = async (data) => {
  return await call('london/provider-update', data)
}

/* 房源数据源 链接 */
export const zooplaLinks = async (data) => {
  return await call('london/provider-zoopla-links', data)
}

/* 房源数据源 增加链接 */
export const zooplaAddLink = async (data) => {
  return await call('london/provider-zoopla-add-link', data)
}

/* 房源数据源 增加链接 */
export const zooplaDelLink = async (data) => {
  return await call('london/provider-zoopla-del-link', data)
}