import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import CenterCard from "./CenterCard";
import { authSignIn } from "api/auth";

import { Alert, Button } from "@mui/material";

export default function AdminLayout() {
  const { signed } = useSelector(appStates)
  return (
    <>
      {signed && <Outlet />}
      {!signed && 
        <CenterCard>
          <Alert severity="error">请先登录!</Alert>
          <Button onClick={()=>authSignIn()} variant="contained">登录网站</Button>
        </CenterCard>
      }
    </>
  )
}
