import { enqueueSnackbar } from "notistack";

/**
 * 首字母大写
 * @param {string} str 
 * @returns string
 * 1 bed flat => 1 Bed Flat
 */
export function titleCase(str) {
  str = str.replace(" to rent", "")
  str = str.toLowerCase();
  var words = str.split(' ');
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');
}

// 租金格式化
export function rentFormat(number) {
  return '£'+(Number(number).toFixed(2).replace(/\.0+$/, '')) + '/周';
}


export function noticeSuccess(message)
{
  enqueueSnackbar(message, { variant: 'success'})
}

export function noticeError(message)
{
  enqueueSnackbar(message, { variant: 'error'})
}


export function baseUrl()
{
  if(process.env.NODE_ENV === 'development') {
    return "http://localhost:3000/";
  } else {
    return "https://london.51room.com/";
  }
}

export function authSite()
{
  if(process.env.NODE_ENV === 'development') {
    return "http://localhost:3001/";
  } else {
    return "https://account.51room.com/";
  }
}

export function adminSite()
{
  if(process.env.NODE_ENV === 'development') {
    return "http://localhost:3002/";
  } else {
    return "https://admin.51room.com/";
  }
}

// 本地缓存站点 版本控制
// 23-05-23
export function versionCheck(version)
{
  const localVersion = localStorage.getItem('version');
  if(!localVersion) localStorage.setItem('version', version);
  else if(localVersion !== version) {
    localStorage.setItem('version', version);
    window.location.reload();
  }
}