// React Router
import { createBrowserRouter } from "react-router-dom";

// Layout
import Layout from 'layouts/Layout';
import ErrorPage from 'pages/ErrorPage';

// Pages
import Homepage from "pages/Homepage";
import Map from "pages/Map";
import Property from 'pages/Property';
import PropertyListExpress from "pages/PropertyListExpress";
import Provider from "pages/Provider";
import AdminLayout from "layouts/AdminLayout";
import QA from "pages/QA";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Homepage />
      },
      {
        path: "map",
        element: <Map />
      },
      {
        path: "property-list-express",
        element: <PropertyListExpress />
      },
      {
        path: "property/:propertyId",
        element: <Property />
      },
      {
        path: "qa",
        element: <QA />
      },
      { // 所有需要Admin验证的页面
        path: 'admin',
        element: <AdminLayout />,
        children:[
          {
            path: "provider",
            element: <Provider />
          },
        ]
      }
    ]
  }
]);

export default router;