import { useState } from "react"
import { Chip, Dialog } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from "dayjs";
import zhCN from 'dayjs/locale/zh-cn'


/* 日期选择器 */
export default function DataPicker(props) {

  const { label, onSelect } = props
  const today = dayjs(Date.now())
  const [open, setOpen] = useState(false)

  const openCalender = () => {
    setOpen(true)
  }

  const handleChange = (date) => {
    onSelect(date.format('YYYY-MM-DD'))
    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)
  }

  return (
    <>
    <Chip label={label?label:today.format('YYYY-MM-DD')} onClick={openCalender} />

    <Dialog open={open}>
      <LocalizationProvider 
        dateAdapter={AdapterDayjs}
        adapterLocale={zhCN}
      >
        <StaticDatePicker 
          onChange={handleChange}
          value={dayjs(label)}
          onAccept={handleAccept}
          slotProps={{
            actionBar: {
              actions: [],
            },
            toolbar: {
              hidden: true,
            },
          }}
        />
      </LocalizationProvider>
    </Dialog>
    </>
  )
}