/* 供应商管理 */
import { useEffect, useState } from "react"
import { providerList } from "api/provider"
import { Card, CardContent, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Check } from "@mui/icons-material"
import ProviderEditor from "components/provider/ProviderEditor"
import ZooplaLinks from "components/provider/ZooplaLinks"

export default function Provider()
{

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  
  const fetchData = async () => {
    setLoading(true)
    const res = await providerList()
    setData(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const newProvider = {
    city_id: 19,
    mark_london: 1
  }

  return (
  <Card>
    <CardContent>
     {loading && <LinearProgress />}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>渠道方</TableCell>
            <TableCell>服务费</TableCell>
            <TableCell>佣金</TableCell>
            <TableCell>Zoopla</TableCell>
            <TableCell><ProviderEditor provider={newProvider} reload={fetchData} /></TableCell>
          </TableRow>
        </TableHead>
        
        
        <TableBody>
        {
          data.map(provider => 
            <TableRow key={provider.id}>
              <TableCell><Typography>{provider.id}</Typography></TableCell>
              <TableCell><Typography>{provider.name}</Typography></TableCell>
              <TableCell>{provider.mark_service_fee === '1' && <Check />}</TableCell>
              <TableCell>{provider.mark_commission === '1' && <Check />}</TableCell>
              <TableCell>{provider.mark_zoopla === '1' && <ZooplaLinks provider={provider} />}</TableCell>
              <TableCell><ProviderEditor provider={provider} reload={fetchData} /></TableCell>
            </TableRow>
          )
        }
        </TableBody>
      </Table>
      
    </CardContent>
  </Card>
  )
}