import { useState, useRef } from 'react'
import { Box, Stack, TextField, InputAdornment, IconButton, Typography, LinearProgress } from '@mui/material'
import { Delete, Link } from '@mui/icons-material'
import Dialog from 'components/Dialog'
import { zooplaAddLink, zooplaDelLink, zooplaLinks } from 'api/provider'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

/* Zoopla 房源抓取 */
export default function ZooplaLinks({provider}) {

  const [urls, setUrls] = useState([])
  const [loading, setLoading] = useState(false)
  const ref = useRef()
  const prefix = "https://www.zoopla.co.uk/to-rent/branch/"

  const fetchData = async () => {
    setLoading(true)
    const res = await zooplaLinks({providerID: provider.id})
    if(res) setUrls(res)
    setLoading(false)
  }

  const open = () => {
    fetchData()
    ref.current.open()
  }

  const delUrl = async (id) => {
    if(window.confirm("确定删除")) {
      await zooplaDelLink({id: id})
      fetchData()
    }
  }

  const handleKeyDown = async (event) => {
    if (event.keyCode === 13) {
      setLoading(true)
      let uri = event.target.value
      uri = uri.replace("/", "")
      const url = prefix+uri+'/'
      await zooplaAddLink({provider_id: provider.id, url: url})
      fetchData()
    }
  };

  return(
    <>
    <IconButton onClick={open}>
      <Link />
    </IconButton>
    

    <Dialog
      ref={ref}
      title={provider.name + " Zoopla URLs"}
      width='lg'
    >
      <Stack spacing={2}>
        <TextField 
          disabled={loading}
          InputProps={{
            startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
            endAdornment: <KeyboardReturnIcon />
          }}
          onKeyDown={handleKeyDown}
        />

        {loading && <LinearProgress />}

        {provider.mark_watermark === '1' && <Typography variant='caption'>图片有水印, 房源抓取时会跳过图片。</Typography>}

        {urls.map(url => 
          <Stack direction='row' spacing={1} key={url.id} alignItems='center'>
            <Typography variant='caption'>{url.url}</Typography>  
            <Box flexGrow={1} />
            <IconButton size="small" onClick={()=>delUrl(url.id)}>
              <Delete fontSize='1' />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Dialog>
    </>

  )
}