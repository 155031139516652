/* 房源信息编辑 */
import { useState, useRef, useEffect } from 'react'

// UI
import { IconButton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Components
import Dialog from "components/Dialog";
import Form from 'components/Form';
import { propertyUpdate } from 'api/property';
import { Add } from '@mui/icons-material';
import propertyForm from './propertyForm';
import { providerList } from 'api/provider';

export default function PropertyAddButton({reload})
{

  const ref = useRef()
  const [data, setData] = useState({
    city_id:19,
    mark_noimg: 1
  })

  const [providers, setProviders] = useState([])
  const [selected, setSelected] = useState(0)
  const fetchData = async () => {
    const res = await providerList()
    if(res) {
      setProviders(res)
      setSelected(res[0].id)
      setData({
        ...data,
        provider_id: res[0].id
      })
    }
  }

  useEffect(()=>{
    fetchData()
  }, [])

  const openEditor = () => {
    ref.current.open()
  }

  const callback = () => {
    ref.current.close()
    reload()
  }

  const handleChange = (event) => {
    setSelected(event.target.value);
    setData({
      ...data,
      provider_id: event.target.value
    })
  }

  return (
    <>
    <IconButton onClick={openEditor}>
      <Add />
    </IconButton>
   
    <Dialog 
      ref={ref}
      title="编辑房源信息"
      fullWidth={true}
    >

      <FormControl fullWidth>
        <InputLabel>供应商</InputLabel>
        <Select
          value={selected}
          onChange={handleChange}
        >
          { providers.map( provider => <MenuItem value={provider.id} key={provider.id}>{provider.name}</MenuItem> )}
        </Select>
      </FormControl>

      <Form 
        data={data} 
        fields={propertyForm} 
        callApi={propertyUpdate}
        callback={callback} 
      />
    </Dialog>
    </>
  )
}