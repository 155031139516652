import { Card,Stack, Typography, CardContent } from "@mui/material";
import { titleCase } from "utils/common";
import { ContextSkeleton } from "components/Skeletons";
export default function PropertyIntroText({property})
{
  
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack>
            {property && <Typography variant="h5">{titleCase(property.name)}</Typography> } 
            {property && <Typography variant="body2">{property.address}</Typography> } 
          </Stack>
          {property?
            <Typography variant="body2" whiteSpace='pre-wrap'>{property.intro}</Typography>
            :
            <ContextSkeleton />
          } 
        </Stack>
      </CardContent>
    </Card>
  )
}