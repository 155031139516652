/* 房源信息编辑 */
import { useState, useEffect, useRef } from 'react'

// UI
import {  Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Components
import Dialog from "components/Dialog";
import Form from 'components/Form';
import { propertyUpdate } from 'api/property';
import { providerList } from 'api/provider';

const form = [
  {
    contents: [
      {
        type: 'checkbox',
        name: 'mark_hide',
        label: '隐藏'
      },
    ]
  },
  {
    contents: [
      {
        type: 'input',
        name: 'name',
        label: '标题'
      },
      {
        type: 'date',
        name: 'availability',
        label: '空房'
      }
    ]
  },
  {
    contents: [
      {
        type: 'input',
        name: 'rent',
        label: '每周租金'
      },
      {
        type: 'input',
        name: 'lat',
        label: '纬度 lat'
      },
      {
        type: 'input',
        name: 'lng',
        label: '经度 lng'
      }
    ]
  },
  {
    type: 'input',
    name: 'address',
    label: '地址'
  },
  {
    type: 'note',
    name: 'intro',
    label: '介绍'
  }
]

export default function PropertyInfoEditor({property, reload})
{

  const ref = useRef()
  const [data, setData] = useState(null)

  const [providers, setProviders] = useState([])
  const [selected, setSelected] = useState(0)
  const fetchData = async () => {
    const res = await providerList()
    if(res) {
      setProviders(res)
      setSelected(Number(property.provider_id))
    }
  }

  useEffect(() => {
    setData(property)
    fetchData()
  }, [property])

  const openEditor = () => {
    ref.current.open()
  }

  const callback = () => {
    ref.current.close()
    reload()
  }

  const handleChange = (event) => {
    setSelected(event.target.value);
    setData({
      ...data,
      provider_id: event.target.value
    })
  }

  return (
    <>
    <Button variant="outlined" onClick={openEditor}>信息</Button>
    
    <Dialog 
      ref={ref}
      title="编辑房源信息"
      fullWidth={true}
    >
      <FormControl fullWidth>
        <InputLabel>供应商</InputLabel>
        <Select
          value={selected}
          onChange={handleChange}
        >
          { providers.map( provider => <MenuItem value={provider.id} key={provider.id}>{provider.name}</MenuItem> )}
        </Select>
      </FormControl>

      <Form 
        data={data} 
        fields={form} 
        callApi={propertyUpdate}
        callback={callback} 
      />
    </Dialog>
    </>
  )
}