
import store from 'redux/store'
import { setPropertyDialog } from 'redux/appSlice'

export function openPropertyDialog(id) 
{
  store.dispatch(setPropertyDialog({id:id, open:true}))
}

export function closePropertyDialog()
{
  store.dispatch(setPropertyDialog({id:null, open:false}))
}