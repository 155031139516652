
import { useRef } from 'react'
import Dialog from "components/Dialog";
import Form from 'components/Form';
import { providerUpdate } from 'api/provider';
import { Button } from '@mui/material';

const form = [
  {
    contents: [
      {
        type: 'checkbox',
        name: 'mark_service_fee',
        label: '服务费'
      },
      {
        type: 'checkbox',
        name: 'mark_commission',
        label: '佣金'
      },
      {
        type: 'checkbox',
        name: 'mark_zoopla',
        label: 'Zoopla'
      },
      {
        type: 'checkbox',
        name: 'mark_watermark',
        label: '图片有水印'
      },
    ]
  },
  {
    type: 'input',
    name: 'name',
    label: '名称'
  },
  {
    type: 'input',
    name: 'drive_folder_id',
    label: 'Drive'
  },
  {
    type: 'input',
    name: 'website',
    label: '官网'
  },
  {
    type: 'note',
    name: 'intro',
    label: '简介'
  },
  {
    type: 'note',
    name: 'contact',
    label: '联系人'
  },
  {
    type: 'note',
    name: 'process',
    label: '预订流程'
  }
]

export default function ProviderEditor({provider, reload})
{

  const ref = useRef()

  const callback = () => {
    reload()
    ref.current.close()
  }

  return (
    <>
    <Button onClick={()=>ref.current.open()}>{provider.id ? '编辑':'新建'}</Button>
    <Dialog
      ref={ref}
      title="编辑渠道"
    >
      <Form data={provider} fields={form} callApi={providerUpdate} callback={callback} />
    </Dialog>
    </>
  )
}