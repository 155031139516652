import { propertySearch } from "api/property"
import { useEffect, useState } from "react"
import { Box, Typography, Stack, LinearProgress, Hidden } from "@mui/material"
import { Filter, FilterChips } from "components/PropertyFilter";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { openPropertyDialog } from "utils/property"

export default function MapPage() {

  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [filter, setFilter] = useState({map: 'map'})

  async function fetchData() {
    setLoading(true)
    setProperties([])
    if(Object.keys(filter).length === 0) return false
    const data = await propertySearch(filter)
    console.log(data)
    if(!data) return false;
    setLoading(false)
    setProperties(data.properties)
  }

  useEffect(() => {
    if(filter.date) {
      fetchData()
    }
  }, [filter])

  const clearFilter = () => {
    setFilter({map: 'map'})
  }


  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems='center' spacing={2}>
        <Typography variant="h5">伦敦地图</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* Desktop */}
        <Hidden mdDown><FilterChips filter={filter} clear={clearFilter} /></Hidden>
        <Filter filter={filter} setFilter={setFilter} open={true} />
      </Stack>

      {/* Mobile */}
      <Hidden mdUp><FilterChips filter={filter} clear={clearFilter} /></Hidden>
      
      {loading && <LinearProgress />}
      <Map data={properties} />

    </Stack>
  )
}

/**
 * 房源列表
 */
const Map = (props) => {

  const { data } = props
  // eslint-disable-next-line no-unused-vars
  const mapOptions ={
    center: {lat: 51.5055863, lng: -0.1684705},
    zoom: 11
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB1bDvfxb2oH49S817960_gdLQi22oUUYY"
  })

  const viewProperty = (id) => {
    openPropertyDialog(id)
  }

  return(
    <>
    {isLoaded && 
    <GoogleMap
      mapContainerStyle={{width:'100%',height:'80vh'}}
      center={mapOptions.center}
      zoom={mapOptions.zoom}
    >
      {
        data && data.map(p => 
        <Marker key={p.id} position={{lat: Number(p.lat), lng: Number(p.lng)}} onClick={()=>viewProperty(p.id)} />
      )}
      
    </GoogleMap>
    }
    </>
  )
}