import { useEffect, useRef, useState } from "react"
import { Button, Chip, Typography, Stack } from "@mui/material"
import FilterListIcon from '@mui/icons-material/FilterList';
import Dialog from "components/Dialog";
import DatePicker from "components/DatePicker";
import Selector from "components/Selector";
import dayjs from "dayjs";

/**
 * 房源搜索 筛选
 */
export const Filter = (props) => {

  const { filter, setFilter, open } = props

  const [bedroom, setBedroom] = useState('不限')
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [minRent, setMinRent] = useState('不限')
  const [maxRent, setMaxRent] = useState('不限')
  const ref = useRef()

  const bedrooms = ['不限', 1, 2, 3, 4, 5]
  const minRents = ['不限',300,400,500,600,700,800,900,1000]
  const maxRents = ['不限',500,600,700,800,900,1000,1200,1300]


  useEffect(() => {
    if(open) ref.current.open()
  }, [])


  const changeDate = (date) => {
    console.log(date)
    setDate(date)
  }

  const changeFilter = () => {
    setFilter({
      ...filter,
      minRent: minRent,
      maxRent: maxRent,
      bedroom: bedroom,
      date: date,
      page: 1
    })
    ref.current.close()
  }

  
  return (
    <>
    <Chip icon={<FilterListIcon fontSize="small" />} label="筛选" onClick={() => ref.current.open()}/>

    <Dialog 
      ref={ref} 
      title="房源筛选"
      width="xs"
    >
      <Stack spacing={2}>

        <Stack direction="row" spacing={1} sx={{px:2}} alignItems='center'>
          <Typography variant="body2"><b>空房</b></Typography>
          <DatePicker label={date} onSelect={changeDate} />
          <Typography variant="body2"><b>卧室</b></Typography>
          <Selector label={bedroom} items={bedrooms} callback={setBedroom} />
        </Stack>

        <Stack direction="row" spacing={1} sx={{px:2}} alignItems='center'>
          <Typography variant="body2"><b>每周租金£</b></Typography>
          <Selector label={minRent} items={minRents} callback={setMinRent} />
          <Typography variant="body2">至</Typography>
          <Selector label={maxRent} items={maxRents} callback={setMaxRent} />
        </Stack>

        <Button variant="contained" onClick={changeFilter}>筛选</Button>
      </Stack>
    </Dialog>
    </>
  )
}

/**
 * 筛选条件展示
 */
export const FilterChips = (props) => {
  const { filter, clear } = props

  return (
    <Stack alignItems="center">
      <Stack direction="row" spacing={1} alignItems='center'>
        {filter.date && <Chip label='筛选中' color="warning" onDelete={clear} />}
      </Stack>
    </Stack>
  )
}