import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";

export default function QA()
{
  return(
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item>
        <Stack spacing={2}>
          <Typography variant="h5">常见问题</Typography>
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <Typography variant="h5">英国公寓租房流程</Typography>
                <Typography variant="body">
                  1. 客户与51ROOM顾问确认房源, 租金和入住时间。<br/><br/>
                  2. 客户缴纳服务费（半周租金）, 签订《51ROOM客户服务协议》。<br/><br/>
                  3. 客户提供个人及担保人信息以及签证、护照、学校offer等电子档材料用于租客信息审核（reference check）<br/><br/>
                  4. 租客信息审核通过后, 签署租房合同并支付押金（5周租金）。<br/><br/>
                  5. 按照合同支付租金。<br/><br/>
                  6. 入住（check in）入住当天, 租客需前往公寓前台管理公司办公室,办理入住手续, 领取钥匙。<br/><br/>
                  7. 租客进入房屋后, 需自行检查房屋设备设施情况, 可拍照记录。如有问题需第一时间联系房东或管理公司。<br/><br/>
                  8. 退房（check out）租期结束, 租客联系房东或管理公司完成退房手续, 包括房屋设施和清洁的检查, 交还钥匙等。<br/><br/>
                  9. 退押金, 如果租客没有损坏房屋和设施, 押金根据租房合同相关条款退还租客。<br/><br/>

                  <b>注：因房东/管理公司要求不同，会有差异，最终以租房合同内容为准。</b>
                </Typography>
              </Stack>

            </CardContent>
          </Card>
        
          <Card>
            <CardContent>
              <Stack spacing={1}>
                <Typography variant="h5">租客需要支付哪些帐单?</Typography>
                <Typography variant="body">
                  <b>如果房租没有包Bill, 那么您需要每月支付</b><br/><br/>
                  1. 水费（Water Bill）<br/><br/>
                  2. 电费（Electricity Bill）<br/><br/>
                  3. 网费（Broadband Bill）<br/><br/>
                  4. 天然气费（Gas Bill）有些房子只用电就没有此项。<br/><br/>
                  5. 地方税（Council Tax）学生身份可以免去此项，需要学生出具在线证明并提交给地方政府（Council）。<br/><br/>
                  <b>其它费用</b> <br/><br/>
                  电视许可证费（TV License Fee）<br/><br/>
                  在英国观看BBC节目需支付的年度费用, 地方政府会发纸质信件表格。<br/><br/>
                  当收到相关信件后，可以填写没有电视设备，不收看此电视节目，然后寄回信件可以免去费用。<br/>
                </Typography>
              </Stack>

            </CardContent>
          </Card>
        
        </Stack>
      </Grid>
    </Grid>
  )
}