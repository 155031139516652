import { Card, CardContent, Grid, Stack } from "@mui/material";

export default function CenterCard({children})
{
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              {children}
            </Stack>
          </CardContent>
        </Card>
      </Grid>         
    </Grid> 
  )
}