import { useState, useEffect, useRef } from "react"

// UI
import { Card, IconButton, Stack, LinearProgress } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Dialog from "components/Dialog"

import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { propertyView } from "api/property";
import { closePropertyDialog } from "utils/property";
import { titleCase } from "utils/common";

import PropertyImgSlider from "components/property/PropertyImgSlider";
import PropertyIntroText from "components/property/PropertyIntroText";
import PropertyInfo from "components/property/PropertyInfo";

export default function PropertyDialog() 
{
  const ref = useRef()

  const [loading, setLoading] = useState(false)
  const [property, setProperty] = useState(null)
  const { propertyDialog, isMobile } = useSelector(appStates)

  const init = async () => {
    ref.current.open()
    setLoading(true)
    const data = await propertyView(propertyDialog.id)
    setLoading(false)
    setProperty(data)
  }

  useEffect(() => {
    if(propertyDialog.open) 
    {
      init()
    }
  }, [propertyDialog])

  const handleClose = () => {
    setLoading(false)
    setProperty(null)
    closePropertyDialog()
  }

  const openPage = () => {
    if(property)
    {
      if(process.env.NODE_ENV === 'development') window.open("http://localhost:3000/property/"+property.id)
      else  window.open("https://london.51room.com/property/"+property.id)
    }
  }

  const Actions = () => <>
    <IconButton onClick={openPage}>
      <OpenInNewIcon sx={{color: 'white'}} />
    </IconButton>
  </>

  return (
    <Dialog 
      ref={ref} 
      onClose={handleClose} 
      title={property?titleCase(property.name):''} 
      fullScreen={isMobile}
      noContentPadding={isMobile}
      fullWidth={true}
      noHeader={loading}
      action={<Actions />}
    >
      {loading && <LinearProgress />}
      {property && 
      <Stack spacing={2}>
        <Card>
          <PropertyImgSlider property={property} />
          <PropertyIntroText property={property} />
        </Card>
        <PropertyInfo property={property} />
      </Stack>
      }
    </Dialog>
  )
}