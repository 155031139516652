import { useEffect, useState } from "react"
import { Button, FormControl, MenuItem, Select, Stack, TextField, InputLabel, Box, FormControlLabel, Checkbox as MuiCheckbox, LinearProgress} from "@mui/material"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import zhCN from 'dayjs/locale/zh-cn'
import { noticeError } from "utils/common";

/*
const form = [
    {
      type: 'date',
      name: 'date',
      label: '转账日期'
    },
    {
      type: 'picker',
      name: 'currency',
      label: '币种',
      options: [
        {name: '英镑', value: 0},
        {name: '人民币', value: 1},
      ]
    },
    {
      type: 'input',
      name: 'amount',
      label: '金额'
    },
    {
      type: 'note',
      name: 'note',
      label: '备注'
    }
  ]
 <Form data={invoice} fields={form} api="Agent/InvoiceUpdate" callback={formCallback} />
*/

export default function Form(props) {

  const { data, fields, callApi, callback } = props
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(fields)
    setFormData({...data})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const changeData = (name, value) => {
    setFormData({
      ...formData,
      [`${name}`]: value
    })
  }

  const save = async () => {
    console.log(formData)
    setLoading(true)
    console.log(validateData(formData, fields))
    if(validateData(formData, fields) === true) {
      const res = await callApi(formData)
      if(callback && res) callback(res)
    } else {
      noticeError('请完善表单-'+validateData(formData, fields))
    }
    setLoading(false)
  }

  return (
    <Stack spacing={2}>
      {formData && fields && fields.map((f, index)=>
        <Box key={index}>
          { !f.contents && <FormField label={f.label} name={f.name} value={formData[f.name]} setValue={changeData} type={f.type} options={f.options} />}
          { f.contents && 
            <Stack direction='row' spacing={2}>
              {f.contents.map(c => 
                <FormField key={c.name} label={c.label} name={c.name} value={formData[c.name]} setValue={changeData} type={c.type} options={c.options} />
              )}
            </Stack>
          }
        </Box>
        
      )}
      {loading && <LinearProgress />}
      {!loading &&
      <Stack direction="row-reverse" spacing={2}>
        <Button variant='contained' onClick={save}>保存</Button>
      </Stack>
      }
    </Stack>
  )
}

function validateData(data, validationRules) {
  

  for (var i = 0; i < validationRules.length; i++) {
    var rule = validationRules[i];

    if (rule.contents) {
      // Handle contents of a group rule
      var groupValidationResult = validateData(data, rule.contents);
      if (groupValidationResult !== true) {
        return groupValidationResult
      }
    } else {
      // Handle individual rules
      var value = data[rule.name];

      // Check if the value exists or is required
      if (rule.required && (value === undefined || value === '')) {
        return rule.label
      }

      // Additional validation based on the rule type
      switch (rule.type) {
        case 'date':
          if (value && isNaN(Date.parse(value))) {
            return rule.label
          }
          break;
        // Additional cases for other types if needed
      }
    }
  }

  return true; // Validation passed
  
}

function FormField(props) {
  const { label, name, value, setValue, options, type } = props


  return (
    <>
      {type === 'input' && <Input label={label} name={name} value={value} setValue={setValue} />}
      {type === 'picker' && <Picker label={label} name={name} selected={value} setSelected={setValue} options={options} />}
      {type === 'note' &&  <Input label={label} name={name} value={value} setValue={setValue} multiline row={10} />}
      {type === 'date' &&  <DatePicker label={label} name={name} date={value} setDate={setValue} />}
      {type === 'checkbox' && <Checkbox label={label} name={name} value={value} setValue={setValue} />}
    </>
  )
}

/*********************************************************************/
/* Input */
function Input(props) {

  const { label, name, value, setValue, ...rest } = props

  const handleChange = (e) => {
    setValue(name, e.target.value)
  }

  return (<TextField label={label} value={value?value:''} onChange={handleChange} fullWidth {...rest} />)
}

/*********************************************************************/
/* Picker */
function Picker(props) {
  const { label, name, selected, setSelected, options } = props;

  const handleChange = (event) => {
    setSelected(name, event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel sx={{backgroundColor: '#fff', paddingX: '0.1rem'}}>{label}</InputLabel>
      <Select
        value={selected}
        onChange={handleChange}
      >
        { options.map( option => <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem> )}
      </Select>
    </FormControl>
  );
}

/*********************************************************************/
/* Date Picker */
function DatePicker(props) {

  const { label, name, date, setDate } = props
  const dateFormat = 'YYYY-MM-DD'

  const changeDate = (newDate) => {
    setDate(name, dayjs(newDate).format(dateFormat).toString())
  }

  return (
    <LocalizationProvider 
        dateAdapter={AdapterDayjs}
        adapterLocale={zhCN}
      >
      <MuiDatePicker  
        label={label}
        format={dateFormat}
        value={dayjs(date)}
        onChange={changeDate}
      />
    </LocalizationProvider>
  )
}

/* Checkbox */
function Checkbox(props) 
{
  const { label, name, value, setValue } = props

  const [ checked, setChecked ] = useState(false)

  // 初始化
  useEffect(() => {
    if(value === '1') setChecked(true)
  }, [])

  const handleChange = (event) => {
    setChecked(event.target.checked)
    setValue(name, event.target.checked?"1":"0")
  }

  return (
    <FormControlLabel 
      label={label}
      control={
        <MuiCheckbox 
          checked={checked} 
          onChange={handleChange} 
        />
      } 
    />
  )
}