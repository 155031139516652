import { useState, useEffect } from "react";
import { Box, Stack, Unstable_Grid2 as Grid, Hidden } from "@mui/material";

import { propertyView } from "api/property";
import { useParams } from "react-router-dom";

import PropertyImgSlider from "components/property/PropertyImgSlider";
import PropertyIntroText from "components/property/PropertyIntroText";
import PropertyAdmin from "components/property/PropertyAdmin";
import PropertyInfo from "components/property/PropertyInfo";

export default function PropertyPage()
{
  
  const { propertyId } = useParams()

  const [property, setProperty] = useState(null)

  const fetchData = async () => {
    setProperty(null)
    const data = await propertyView(propertyId)
    setProperty(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Grid container spacing={2}>

      <Hidden lgDown>
        <Grid lg={3}></Grid>
      </Hidden>

      <Grid lg={4} md={7} xs={12}>
        <PropertyImgSlider property={property} />
        <PropertyIntroText property={property} />
        <Box sx={{marginTop:2}} />
        <PropertyAdmin property={property} reload={fetchData} /> 
      </Grid>

      <Grid lg={2} md={5} xs={12}>
        <Stack spacing={2}>
          <PropertyInfo property={property} />
          
        </Stack>
      </Grid>
    </Grid>
  )
}