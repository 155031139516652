import { Card, Divider, Stack, Typography, CardContent, Grid } from "@mui/material";

import FloorPlan from "./FloorPlan";
import Map from "./Map";
import CustomerService from "./CustomerService";
import { ContextSkeleton } from "components/Skeletons";
import BookingForm from "components/booking/BookingForm"; 

/* 房源价格等信息 */
export default  function PropertyInfo({property})
{
  
  return (
    <Card>
      <CardContent>
        
        { property ?
          <Grid container spacing={2}>

          <Grid item xs={12}>
            <Stack>
              <Typography variant="h5">£{property.rent} 每周起</Typography>
              <Stack direction="row" spacing={2}>
                <Typography variant="body2">{'编号 ' + property.id}</Typography>
                {property.availability && <Typography variant="body2">{'空房 '+property.availability}</Typography>}
              </Stack>
            </Stack>
          </Grid>

          <Grid item>
            <Map lat={property.lat} lng={property.lng} />
          </Grid>

          <Grid item>
            <FloorPlan url={property.floorplan} />
          </Grid>

          <Grid item xs={12}><Divider /></Grid>

          <Grid item>
            <CustomerService />
          </Grid>
          <Grid item>
            <BookingForm property={property} />
          </Grid>
        </Grid>
        :
        <ContextSkeleton />
        }
      </CardContent>
    </Card>
  )
}