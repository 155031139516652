import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { Button, Card, Stack, Typography, CardContent, Chip, Divider } from "@mui/material";

import PropertyInfoEditor from "components/property/PropertyInfoEditor";
import PropertyImgEditor from "./PropertyImgEditor";
import ProviderNote from "components/provider/ProviderNote";
import AdminComments from "./AdminComments";

/* 内部房源管理 */
export default function PropertyAdmin({property, reload})
{

  const { isStaff } = useSelector(appStates)
  return (
    <>
    {isStaff && property && 
    <Card>
      <CardContent>
        <Stack spacing={2}> 
          <Stack>
            <Typography variant="h5">房源管理</Typography>
            <Typography variant="body2">企业微信登录后可操作</Typography>
          </Stack>

          

          <Stack direction="row" spacing={1}>
            <PropertyInfoEditor property={property} reload={reload} />
            <PropertyImgEditor property={property} reload={reload} />
            {property.source_url && <Button variant="outlined" onClick={()=>window.open(property.source_url)}>官网</Button>}
          </Stack>

          <Divider />

          <Stack direction="row" spacing={1}>
            <Chip label={property.provider.id + '-' +property.provider.name} />
            {property.provider.mark_service_fee === '1' && <Chip label="服务费" />}
            {property.provider.mark_commission === '1' && <Chip label="佣金" />}
          </Stack>

          <Stack direction="row" spacing={1}>
            <ProviderNote provider={property.provider} />
            <Button variant="outlined" onClick={()=>window.open(property.provider.drive_folder_id)}>网盘</Button>
          </Stack>

          <Divider />

          <AdminComments propertyID={property.id} />

        </Stack>
      </CardContent>
    </Card>
    }
    </>
    
  )
}