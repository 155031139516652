import { call, upload } from "./api";

/**
 * 房源搜索
 * @param {object} filter {price, avalibility, bedroom, ...}
 * @returns property object array / null
 */
export const propertySearch = async (filter) => {
  return await call('london/property-search', filter)
}

/**
 * 房源信息
 * @param {int} id property id
 * @returns property object / null
 */
export const propertyView = async (id) => {
  return await call('london/property-view/' + id)
}

/**
 * 地址搜索
 * @param {string} keywords 
 * @returns 
 */
export const addressSearch = async (keywords) => {
  return await call('london/address-search', {keywords: keywords})
}

/**
 * 更新房源
 * @param {object} data Property Object
 * @returns 
 */
export const propertyUpdate = async (data) => {
  return await call('london/property-update', data)
}

export const photoSort = async (items) => {
  return await call('london/photo-sort', {imgs: items})
}

export const photoDel = async (id) => {
  return await call('london/photo-del', {imgID: id})
}

export const photoSetFloorPlan = async (id) => {
  return await call('london/photo-set-floorplan', {imgID: id})
}

/* 无图房源 */
export const propertyListNoImg = async (data) => {
  return await call('london/property-list-no-img', data)
}

/* 上传房源 */
export const propertyUpload = async (data) => {
  return await upload('london/property-upload', data)
}