/* 订单提交表单 */
import { useRef } from "react";
import { Button } from "@mui/material";
import Form from "components/Form";
import Dialog from "components/Dialog";
import { noticeError, noticeSuccess } from "utils/common";
import { bookingSubmit } from "api/booking";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import dayjs from "dayjs";
import { authSignIn } from "api/auth";

const form = [
  {
    type: 'input',
    name: 'customer_name',
    label: '姓名'
  },
  {
    type: 'date',
    name: 'check_in',
    label: '入住时间'
  },
  {
    type: 'input',
    name: 'customer_email',
    label: 'Email'
  },
  {
    type: 'input',
    name: 'customer_mobile',
    label: '电话'
  },
  {
    type: 'note',
    name: 'note',
    label: '备注'
  }
]


export default function BookingForm({property})
{
  const ref = useRef()

  const { signed } = useSelector(appStates)

  const data = {
    customer_name: "",
    customer_email: "",
    customer_mobile: "",
    check_in: dayjs().format('YYYY-MM-DD'),
    note: "",


    price: property.rent,
    accommodation_id: property.id,
    accommodation: property.address,
    room_type: property.name
  }

  const openForm = () => {
    if(signed) ref.current.open()
    else {
      noticeError("请先登录!")
      authSignIn()
    }
  }

  const callback = () => {
    ref.current.close()
    noticeSuccess("申请已提交!")
  }

  return (
    <>
    <Button variant="contained" onClick={openForm}>提交申请</Button>
    <Dialog 
      title="提交申请"
      subtitle="请先与顾问咨询确认后再提交"
      ref={ref}
    >
      <Form fields={form} data={data} callback={callback} callApi={bookingSubmit}  />
    </Dialog>
    </>
  )
}