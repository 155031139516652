import { useRef } from "react";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { Button } from "@mui/material";
import Dialog from "components/Dialog";

/* 房型图 */
export default function FloorPlan ({url}) {

  const ref = useRef()
  const open = () => {
    ref.current.open()
  }

  const { isMobile } = useSelector(appStates)

  return (
  <>
  {url && <Button variant="outlined" onClick={open}>房型图</Button>}
  <Dialog
    ref={ref} 
    title="房型图" 
    width="lg"
    fullScreen={isMobile}
    noContentPadding={isMobile}
  >
    <img src={url} alt="" width="100%" loading="lazy" />
  </Dialog>
  </>
  )
}