import { useEffect, useState } from "react"
import { propertyListNoImg } from "api/property"

import { Card, Stack, Pagination, Unstable_Grid2 as Grid, Hidden, Typography, CardContent, Divider, Box, Chip, Link, LinearProgress } from "@mui/material"
import { baseUrl, rentFormat, titleCase } from "utils/common";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { openPropertyDialog } from "utils/property";
import PropertySearchButton from "components/property/PropertySearchButton";
import PropertyAddButton from "components/property/PropertyAddButton";
import { PropertyUploadButton } from "components/property/PropertyUploadButton";

export default function PropertyListExpress()
{
  const { isStaff } = useSelector(appStates)
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState(null);
 
  const [loading, setLoading] = useState(false)


  const fetchData = async () => {
    setLoading(true)
    const res = await propertyListNoImg({page: page})
    if(res) {
      setData(res.properties)
      setPages(res.pages)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const changePage = (event, number) => {
    setPage(number)
  }

  return (

    <Grid container spacing={2}>

      <Hidden lgDown>
        <Grid lg={3}></Grid>
      </Hidden>

      <Grid lg={5} md={12} xs={12}>
        <Stack spacing={1}>

          {/* 标题栏 */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">房源速递</Typography>
            <Box flexGrow={1} />
            {isStaff && <PropertyUploadButton callback={fetchData} />}
            {isStaff && <PropertyAddButton reload={fetchData} />}
            <PropertySearchButton />
          </Stack>


        <Card>
          {!loading && 
          <CardContent>
            {data && data.map(property => 
            <Stack spacing={1} key={property.id}>

              <PropertyListItem property={property} />
             
              <Box pt={1} pb={1} >
                <Divider />
              </Box>
             
            </Stack>  
            )}

            <Stack alignItems='center'>
            {pages > 1 && <Pagination count={pages} page={page} variant="outlined" shape="rounded" onChange={changePage} />}
            </Stack>
          </CardContent>
          }

          {loading && <LinearProgress />}
        </Card>

        </Stack>
      </Grid>
    </Grid>

   

  )
}


export function PropertyListItem({property})
{

  const { mobile } = useSelector(appStates)

  const viewProperty = (property) => {
    if(mobile) openPropertyDialog(property.id)
    else window.open(baseUrl() + 'property/'+property.id)
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Stack>
          <Link href={"#"+property.id} onClick={()=>viewProperty(property)}><Typography variant="h6">{titleCase(property.name)}</Typography></Link>
          <Typography variant="body2">{property.address}</Typography>
        </Stack>
        <Box flexGrow={1}></Box>
        <Typography variant="h5" sx={{minWidth: '9rem', textAlign: 'right'}}>{rentFormat(property.rent)}</Typography>
      </Stack>
      
      <Stack direction="row" spacing={2}>
        <Chip label={'空房 ' + property.availability} />
        <Chip label={'编号 ' + property.id} />
      </Stack>
    </Stack>
  )
}