/* 整站构架 */
// Router
import { Outlet } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
// Mui
import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";
// Components
import PropertyDialog from "components/property/PropertyDialog";
import Header from "layouts/components/Header";

export default function Layout()
{

  const { isMobile } = useSelector(appStates)
  const boxStyle = isMobile?{px:0, py:8}:{px:2, py:8}

  return (
    
    <SnackbarProvider 
      autoHideDuration={2000}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Header isMobile={isMobile} />
     
      <Box sx={boxStyle}>
        <Outlet />
      </Box>

      <PropertyDialog />
    </SnackbarProvider>
  )
}