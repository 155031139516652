import { useRef, useState } from "react";

import { Upload } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { propertyUpload } from "api/property";


export function PropertyUploadButton({callback}) {

  const ref = useRef()

  const [loading, setLoading] = useState(false)

  const handleUpload = async (e) => {
    setLoading(true)
    const files = e.target.files;
    const formData = new FormData()
    formData.append('uploadedfile', files[0])
    await propertyUpload(formData)
    callback()
    setLoading(false)
  }

  const handleClick = (e) => {
    ref.current.click();
  }

  return (
  <>
    <input
      accept=".csv"
      type="file"
      ref={ref}
      onChange={handleUpload}
      style = {{display:'none'}}
    />
    {!loading && 
    <IconButton onClick={handleClick}>
      <Upload />
    </IconButton>
    }
    {loading && <CircularProgress />}
  </>
  )
}