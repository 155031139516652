/* 房源信息编辑 */
import { useState, useEffect, useRef } from 'react'

// UI
import {Button, Grid, Stack, IconButton } from '@mui/material'
import AspectRatio from '@mui/joy/AspectRatio';
import CloseIcon from '@mui/icons-material/Close'
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';

// Sortable
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


// Components
import Dialog from "components/Dialog";
import { photoDel, photoSetFloorPlan, photoSort } from 'api/property';
import { noticeSuccess } from 'utils/common';

export default function PropertyImgEditor({property, reload})
{

  const ref = useRef()

  const openEditor = () => {
    ref.current.open()
  }

  return (
    <>
    <Button variant="outlined" onClick={openEditor}>图片</Button>
    
    <Dialog 
      ref={ref}
      title="编辑房源图片"
      fullWidth={true}
      width="lg"
      onClose={reload}
    >
      <EditPhotos property={property} />
    </Dialog>
    </>
  )
}

const EditPhotos = (props) => {

  const { property } = props

  const [items, setItems] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(property.imgs)
    console.log(property.imgs)
  }, [property])

  const upload = (e) => {
    const files = e.target.files;
    const formData = new FormData()
    formData.append('propertyID', property.id)
   
    for(let i=0; i<files.length; i++) {
      formData.append('files[]', files[i])
    }

    /*
    RSUpload('Photo/Upload', formData)
      .then(data => {
        setItems(data)
      })*/
  }

  const clickHandler = (e) => {
    e.target.value = null
  }

  const removeItem = (imgID) => {
    const newItems = items.filter(item => item.id !== imgID)
    setItems(newItems);
  }

  return (
    <>

    {/*
    
    <Box mb={2} alignItems="center" justifyContent="center" display="flex">
      <Button variant="contained" component="label">
        上传图片
        <input hidden accept="image/*" multiple type="file" onChange={upload} onClick={clickHandler} />
      </Button>
    </Box>

    */}
    
    {items && 
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={items}
        >
          <Grid container spacing={2}>
          {items.map((item, index) => <SortableItem key={index} id={item.id} item={item} setItems={removeItem} />)} 
          </Grid>
        </SortableContext>
      </DndContext>
    }
    </>
  )
  
  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = items.map(i => i.id).indexOf(active.id);
      const newIndex = items.map(i => i.id).indexOf(over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      photoSort(newItems);
    }
  }
}

function SortableItem(props) {
  const { item, setItems } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteImg = (imgID) => {
    if(window.confirm('确定删除?')){
      photoDel(imgID)
      setItems(imgID)
    }
  }

  const setFloorPlan = (imgID) => {
    photoSetFloorPlan(imgID)
    noticeSuccess('已设置房型图')
  }
  
  return (
    <Grid item xs={2} ref={setNodeRef} style={style} >

      <Stack sx={{position: 'absolute', zIndex: 200}}>
        <IconButton onClick={()=>deleteImg(item.id)}>
          <CloseIcon fontSize='1' />
        </IconButton>

        <IconButton onClick={()=>setFloorPlan(item.id)}>
          <ImageAspectRatioIcon fontSize='1' />
        </IconButton>
      </Stack>

      <AspectRatio ratio="3/2" >
        <img src={item.url} alt="" width="100%" loading="lazy" {...attributes} {...listeners} />
      </AspectRatio>

    </Grid>
  )
}
